<template>
	<div class="companyOf">
		<div class="companyOf-top">
			<div class="companyOf-top-img">
				<img v-lazy="topImg" :key="topImg" alt="公司介绍" />
			</div>
			<div class="top-center">
				<p>捷途慧声科技有限公司</p>
				<span>国内领先的AI服务解决方案提供商</span>
				<el-button
					class="banner-btn"
					plain
					@click="
						() => {
							this.$router.push('/formSub')
						}
					"
					>联系我们</el-button
				>
			</div>
		</div>

		<div class="content">
			<div class="navigation">
				<div
					class="iconNav-item"
					v-for="(i, index) in navList"
					:key="index"
					@click="handleNav(i.ref)"
				>
					<div class="iconNav-item-img">
						<img
							v-lazy="i.iconImg"
							:key="i.iconImg"
							alt="iconImg"
						/>
					</div>

					<p>{{ i.name }}</p>
				</div>
			</div>
			<div ref="aboutUsRef">
				<about-us />
			</div>
			<div ref="milestoneRef">
				<Milestone />
			</div>

			<div ref="honorRef">
				<Honor />
			</div>
			<div ref="addressRef">
				<Address />
			</div>
		</div>
	</div>
</template>

<script>
import AboutUs from './childCpn/AboutUs'
import Milestone from './childCpn/Milestone'
import Honor from './childCpn/Honor'
import Address from './Address/index'
export default {
	name: 'companyOf',
	data() {
		return {
			topImg: require('@/assets/img/company/banner.jpg'),
			navList: [
				{
					id: 0,
					iconImg: require('@/assets/img/company/nav/nav1.png'),
					name: '关于我们',
					ref: 'aboutUsRef',
				},
				{
					id: 1,
					iconImg: require('@/assets/img/company/nav/nav2.png'),
					name: '发展历程',
					ref: 'milestoneRef',
				},
				{
					id: 2,
					iconImg: require('@/assets/img/company/nav/nav3.png'),
					name: '企业荣誉',
					ref: 'honorRef',
				},
				{
					id: 3,
					iconImg: require('@/assets/img/company/nav/nav4.png'),
					name: '联系我们',
					ref: 'addressRef',
				},
			],
		}
	},
	created() {},
	mounted() {},
	watch: {},
	methods: {
		handleNav(ref) {
			this.$refs[ref].scrollIntoView(true)
		},
	},
	components: {
		AboutUs,
		Milestone,
		Honor,
		Address,
	},
	computed: {},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
.companyOf {
	.companyOf-top {
		width: 100%;
		overflow: hidden;
		height: 6.76rem;
		position: relative;
		.companyOf-top-img {
			width: 100%;
			height: 100%;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.top-center {
			width: calc(100% - 6.4rem);
			min-width: 174px;
			max-width: 6.8rem;
			height: 2rem;
			position: absolute;
			left: 3.2rem;
			top: 0;
			bottom: 0;
			margin: auto;
			text-align: left;
			p {
				font-size: 0.64rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 500;
				line-height: 0.72rem;
				color: #5e6172;
				margin-bottom: 0.32rem;
			}
			span {
				display: block;
				font-size: 0.24rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 400;
				color: #5e6172;
				line-height: 0.24rem;
				letter-spacing: 0.5em;
				margin-bottom: 0.45rem;
			}
			.banner-btn {
				width: 1.66rem;
				height: 0.42rem;
				background-color: transparent;
				color: #5e6172;
				font-size: 0.18rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 400;
				line-height: 0.24rem;
				letter-spacing: 1px;
				border-radius: 0.04rem;
				&:hover {
					border: 1px solid transparent;
					background-color: #ececef;
				}
			}
		}
	}

	.content {
		position: relative;
	}
	.navigation {
		width: calc(100% - 6.3rem);
		height: 1.64rem;
		border-radius: 0.08rem;
		background-color: #ffffff;
		box-shadow: 0 4px 10px 0px #d1ddeb;
		position: absolute;
		top: -0.6rem;
		left: 0;
		right: 0;
		margin: auto;
		display: flex;
		justify-content: space-around;
		align-items: center;
		z-index: 9;
		.iconNav-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			&-img {
				width: 0.6rem;
				height: 0.6rem;
				margin: 0 0.18rem;
			}
			p {
				font-size: 0.24rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 700;
				color: #687181;
				line-height: 1;
				margin-top: 0.16rem;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.companyOf {
		.companyOf-top {
			height: 178px;
			.top-center {
				height: 65px;
				width: calc(100% - 80px);
				left: 40px;
				p {
					font-size: 16px;
					font-weight: 700;
					color: #717e92;
					line-height: 22px;
					margin-bottom: 1px;
				}
				span {
					font-size: 8px;
					color: #717e92;
					line-height: 12px;
					letter-spacing: 3px;
				}
				.banner-btn {
					width: 59px;
					height: 22px;
					font-size: 10px;
					color: #717e92;
					line-height: 10px;
					padding: 0;
					border: 0.1px solid #717e92;
					border-width: 0.01vw;
					&::after {
						transform: scale(0.5);
					}
				}
			}
		}
		.navigation {
			width: calc(100% - 32px);
			height: 44px;
			top: -21px;
			.iconNav-item {
				&-img {
					width: 16px;
					height: 16px;
				}
				p {
					font-size: 6px;
					margin-top: 4px;
				}
			}
		}
	}
}
</style>
