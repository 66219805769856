//公司文字介绍
export const companyProfile = [
	//公司文字介绍
	'杭州捷途慧声科技有限公司是国内领先的AI服务解决方案提供商。具有多年的人工智能行业从业经验，主要专注于智能语音、智能图像、自然语义理解等人工智能技术的研究与应用。',
	'公司核心技术依托人工智能领域的先进学术研究和强大科研力量，并不断地在此基础上探索，打造AI+行业，推动人工智能与行业的融合创新，形成独具特色的研发成果。公司在智能语音领域的不断研发，结合对行业业务的深刻理解，为公安、法院、检察院、纪委监察委、海关等公检法行业用户提供基于人工智能技术的语音识别和图像识别应用的解决方案和产品。项目覆盖全国各省市县，旨在实现对AI技术的需求，服务广大用户，提供专业的技术解决方案。',
	'为了不断地完善产品的设计，满足政法行业国产化需求，同飞腾、华为和长城等合作伙伴之间相互配合，共同做出努力。打造全方位支持的语音识别和图像识别产品及解决方案，助力行业信息化的建设发展，全面提升行业智能化水平。',
	'未来，捷途慧声将秉承“聚焦 创新 执行 分享”的核心价值，坚持“以人为本、技术创新、专注产品、用心服务”的行动准则，以“建设一流人工智能服务商”为愿景，不断开拓创新，为智能化产业发展贡献力量。',
]
//里程碑详情描述
export const companyMilestoneList = [
	//里程碑详情描述
	{
		title: '杭州捷途慧声科技有限公司成立',
		content: [
			'杭州捷途慧声科技有限公司成立',
			'依托人工智能技术研究',
			'拓展政法行业智能语音技术',
		],
		timestamp: '2016年',
	},
	{
		title: '新产品智能语音庭审系统开发',
		content: [
			'新产品智能语音庭审系统开发',
			'南京铁路法院项目合作',
			'中标龙门中院项目',
			'浙江海盐县公安项目合作',
		],
		timestamp: '2017年',
	},
	{
		title: '智能语音庭审系统软件评测通过',
		content: [
			'智能语音庭审系统软件评测通过',
			'新产品电子卷宗系统开发',
			'浙江海盐县公安局智能审讯项目获浙江省公安厅创新金奖',
			'荣获浙江省科技型中小企业证书',
		],
		timestamp: '2018年',
	},
	{
		title: '我们搬家啦，迁入未来科技城海创园19号楼203、204室，开启新的征程',
		content: [
			'我们搬家啦，迁入未来科技城海创园19号楼203、204室，开启新的征程',
			'新产品智能语音审讯系统开发发布',
			'自主研发ASR、NLP、OCR核心算法',
			'荣获国家高新技术企业证书',
			'公安“办案一体化”项目合作',
		],
		timestamp: '2019年',
	},
	{
		title: '慧听智能语音审讯系统V1.0同华为云鲲鹏云服务完成兼容性认证',
		content: [
			'慧听智能语音审讯系统V1.0同华为云鲲鹏云服务完成兼容性认证',
			'智能语音识别系统V1.0与统信服务器操作系统V20完成兼容性认证',
			'获得杭州市“雏鹰计划”企业证书',
			'获得质量管理体系认证证书',
			'推出语音助手（输入法）产品',
		],
		timestamp: '2020年',
	},
	{
		title: '慧听智能语音审讯系统与长城擎天DF7系列服务器（飞腾FT2000+/64+银河麒麟服务器操作系统V10）和世恒DF7系列终端（飞腾FT2000+/4+银河麒麟桌面操作系统V10）完成兼容性认证',
		content: [
			'慧听智能语音审讯系统与长城擎天DF7系列服务器（飞腾FT2000+/64+银河麒麟服务器操作系统V10）和世恒DF7系列终端（飞腾FT2000+/4+银河麒麟桌面操作系统V10）完成兼容性认证',
			'慧听智能语音识别系统与方德高可信服务器操作系统V4.0完成兼容性认证',
			'台州仲裁院领导莅临我司参观，并对慧听智能语音庭审系统进行了试用',
			'参展2021全球人工智能技术博览会',
			'入会浙江省软件行业协会成为会员',
			'主要产品申请获得浙江省软件行业协会颁发的软件产品证书',
			'获得信息技术服务管理体系认证证书',
			'获得安全管理体系认证证书',
			'OCR适配国产平台和操作系统',
		],
		timestamp: '2021年',
	},
	{
		title: '海光产业生态合作组织成员单位',
		content: [
			'海光产业生态合作组织成员单位',
			'慧听智能审讯系统完成与麒麟软件NeoCertify认证',
			'慧听异常声音分析系统与人大金仓金仓数据库管理系统完成兼容性认证',
			'慧听异常声音分析系统V1.0与华为鲲鹏基础技术架构完成认证',
			'申请多项实用新型专利证书、外观专利证书',
			'8款产品收录信创图谱',
			'慧听异常声音分析系统参与鲲鹏应用创新大赛数字政府赛道荣获奖项',
		],
		timestamp: '2022年',
	},
	{
		title: '荣获2023中国软件技术人工智能技术标杆企业奖',
		content: [
			'荣获2023中国软件技术人工智能技术标杆企业奖',
			'发布机架式语音识别系统',
			'发布智能语音质检解决方案',
			'发布信创计算盒子',
			'荣获浙江省软件核心竞争力企业证书',
			'华为鲲鹏应用创新大赛荣获佳绩并入围全国总决赛',
			'受邀参加全球人工智能技术大会',
			'沈阳分公司成立',
			'乔迁大吉，公司搬迁至余杭区利尔达物联网科技园1幢1102室',
			'捷途慧声正式成为openKylin社区成员',
		],
		timestamp: '2023年',
	},
	// {
	//   title: "慧听智能语音审讯系统V1.0同华为云鲲鹏云服务完成兼容性认证",
	//   content: ["慧听智能语音审讯系统V1.0同华为云鲲鹏云服务完成兼容性认证"],
	//   timestamp: "未来",
	// },
]

//企业荣誉，
export const horizontalImg = [
	{
		src: window.imgUrl + '/enterprise39.jpg',
	},
	{
		src: window.imgUrl + '/enterprise40.jpg',
	},
	{
		src: window.imgUrl + '/enterprise41.jpg',
	},
	{
		src: window.imgUrl + '/enterprise42.jpg',
	},
	{
		src: window.imgUrl + '/enterprise43.jpg',
	},
	{
		src: window.imgUrl + '/enterprise44.jpg',
	},
	{
		src: window.imgUrl + '/enterprise45.jpg',
	},
	{
		src: window.imgUrl + '/enterprise46.jpg',
	},
	{
		src: window.imgUrl + '/enterprise47.jpg',
	},
	{
		src: window.imgUrl + '/enterprise48.jpg',
	},
	{
		src: window.imgUrl + '/enterprise1.jpg',
	},
	{
		src: window.imgUrl + '/enterprise2.jpg',
	},
]

export const enterpriseImg = [
	{
		src: window.imgUrl + '/enterprise3.jpg',
	},
	{
		src: window.imgUrl + '/enterprise4.jpg',
	},
	{
		src: window.imgUrl + '/enterprise5.jpg',
	},
	{
		src: window.imgUrl + '/enterprise6.jpg',
	},
	{
		src: window.imgUrl + '/enterprise7.jpg',
	},
	{
		src: window.imgUrl + '/enterprise8.jpg',
	},
	{
		src: window.imgUrl + '/enterprise9.jpg',
	},
	{
		src: window.imgUrl + '/enterprise10.png',
	},
	{
		src: window.imgUrl + '/enterprise11.png',
	},
	{
		src: window.imgUrl + '/enterprise12.jpg',
	},
	{
		src: window.imgUrl + '/enterprise13.png',
	},
	{
		src: window.imgUrl + '/enterprise14.jpg',
	},
	{
		src: window.imgUrl + '/enterprise15.png',
	},
	{
		src: window.imgUrl + '/enterprise16.png',
	},
	{
		src: window.imgUrl + '/enterprise17.jpg',
	},
	{
		src: window.imgUrl + '/enterprise18.jpg',
	},
	{
		src: window.imgUrl + '/enterprise19.png',
	},
	{
		src: window.imgUrl + '/enterprise20.jpg',
	},
	{
		src: window.imgUrl + '/enterprise21.png',
	},
	{
		src: window.imgUrl + '/enterprise22.png',
	},
	{
		src: window.imgUrl + '/enterprise23.png',
	},
	{
		src: window.imgUrl + '/enterprise24.png',
	},
	{
		src: window.imgUrl + '/enterprise25.png',
	},
	{
		src: window.imgUrl + '/enterprise26.jpg',
	},
	{
		src: window.imgUrl + '/enterprise27.png',
	},
	{
		src: window.imgUrl + '/enterprise28.png',
	},
	{
		src: window.imgUrl + '/enterprise29.jpg',
	},
	{
		src: window.imgUrl + '/enterprise30.jpg',
	},
	{
		src: window.imgUrl + '/enterprise31.jpg',
	},
	{
		src: window.imgUrl + '/enterprise32.jpg',
	},
	{
		src: window.imgUrl + '/enterprise33.png',
	},
	{
		src: window.imgUrl + '/enterprise34.png',
	},
	{
		src: window.imgUrl + '/enterprise35.png',
	},
	{
		src: window.imgUrl + '/enterprise36.png',
	},
	{
		src: window.imgUrl + '/enterprise37.png',
	},
	{
		src: window.imgUrl + '/enterprise38.png',
	},
]
