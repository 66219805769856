<template>
	<baidu-map
		class="bm-view"
		ak="YOUR_APP_KEY"
		:center="center"
		:zoom="zoom"
		@ready="handler"
	>
		<!-- <bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-scale> -->
	</baidu-map>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
// import styleData from "@/views/aboutUs/custom_map_config.json";
export default {
	data() {
		return {
			center: { lng: 0, lat: 0 },
			zoom: 3,
			// styleData: styleData,
		}
	},
	methods: {
		handler({ BMap, map }) {
			this.center.lng = 123.436421
			this.center.lat = 41.767141
			this.zoom = 17
			map.enableScrollWheelZoom(new BMap.Point(123.436421, 41.767141), 20)
			// map.setMapStyle({style:'midnight'});//暗黑模式
			//单独设定图标的样式
			// var icon = new BMap.Icon(
			//   "图片地址",
			//   new BMap.Size(30, 32),
			//   {
			//     anchor: new BMap.Size(30, 30),
			//   }
			// );
			var point = new BMap.Point(123.436421, 41.767141) // 创建点坐标

			// var marker = new BMap.Marker(point, {icon:icon});//替换icon
			var marker = new BMap.Marker(point) // 创建标注
			map.addOverlay(marker) // 将标注添加到地图中
			// eslint-disable-next-line no-undef
			marker.setAnimation(BMAP_ANIMATION_BOUNCE) //跳动的动画

			var opts = {
				width: 200, // 信息窗口宽度
				height: 100, // 信息窗口高度
				title: '分公司地址', // 信息窗口标题
				message: '杭州捷途慧声科技有限公司沈阳分公司',
			}
			var infoWindow = new BMap.InfoWindow(
				'沈阳市三好街五里河城C1单元801室',
				opts
			) // 创建信息窗口对象
			marker.addEventListener('click', function () {
				map.openInfoWindow(infoWindow, point) //开启信息窗口
			})

			var navigationControl = new BMap.NavigationControl({
				// 靠左上角位置
				// eslint-disable-next-line no-undef
				anchor: BMAP_ANCHOR_TOP_LEFT,
				// LARGE类型
				// eslint-disable-next-line no-undef
				type: BMAP_NAVIGATION_CONTROL_LARGE,
				// 启用显示定位
				enableGeolocation: true,
			})
			map.addControl(navigationControl)
		},
	},
	components: {
		BaiduMap,
	},
	mounted() {},
}
</script>

<style>
.bm-view {
	width: 100%;
	height: 4rem;
}
/* 去除百度地图版权那行字 和 百度logo */
.BMap_cpyCtrl {
	display: none !important;
}
.anchorBL {
	display: none !important;
}
.BMap_Marker {
	div {
		width: 39px !important;
	}
	img {
		object-fit: initial !important;
	}
}
@media screen and (max-width: 768px) {
	.bm-view {
		height: 197px !important;
	}
}
</style>
