<!-- 关于我们-公司介绍-里程碑 -->
<template>
	<div class="Milestone-warp">
		<div class="milestone" v-lazy:background-image="milestoneSrc">
			<div class="milestone-center">
				<div class="milestone-inner">
					<div class="milestone-inner-title">公司发展历程</div>
					<div
						class="timeline"
						v-for="(i, index) in milestoneList"
						:key="index"
					>
						<div class="timeline-time">{{ i.timestamp }}</div>
						<div class="timeline-lt"></div>
						<div class="timeline-node"></div>
						<div class="timeline-triangle"></div>
						<div
							class="timeline-card"
							@mouseover="overCard(index)"
							@mouseout="outCard(index)"
						>
							<div class="timeline-card-title">{{ i.title }}</div>
							<div class="timeline-card-content">
								<p
									v-for="(e, index) in i.content"
									:key="index"
									:class="{ pActive: activeIndex == index }"
									@mouseover="
										() => {
											activeIndex = index
										}
									"
								>
									{{ e }}
								</p>
							</div>
						</div>
					</div>
					<div class="timeline">
						<div class="timeline-time">未来</div>
						<div class="timeline-lt"></div>
						<div class="timeline-node"></div>
						<div class="timeline-triangle"></div>
						<div class="timeline-card">
							<div class="timeline-card-title">
								未来很大，让我们一起为智能化产业发展贡献力量
							</div>
							<div class="timeline-card-content"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="milestone-left"></div>
			<div class="milestone-right"></div>
		</div>
	</div>
</template>

<script>
import { companyMilestoneList } from './mock'
export default {
	name: 'Milestone',
	data() {
		return {
			milestoneList: companyMilestoneList,
			milestoneSrc: require('@/assets/img/company/2.png'),
			activeIndex: 0,
		}
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		//鼠标移入
		overCard(index) {
			let cardObj = document.querySelectorAll('.timeline-card')[index]
			cardObj.querySelector('.timeline-card-title').style.display = 'none'
			cardObj.querySelector('.timeline-card-content').style.display =
				'block'
			document.querySelectorAll('.timeline-triangle')[
				index
			].style.display = 'block'

			let line = document.querySelectorAll('.timeline')[index]
			line.querySelector('.timeline-lt').style.height =
				cardObj.querySelector('.timeline-card-content').offsetHeight +
				'px'
		},
		outCard(index) {
			let cardObj = document.querySelectorAll('.timeline-card')[index]
			cardObj.querySelector('.timeline-card-title').style.display =
				'block'
			cardObj.querySelector('.timeline-card-content').style.display =
				'none'
			document.querySelectorAll('.timeline-triangle')[
				index
			].style.display = 'none'
			let line = document.querySelectorAll('.timeline')[index]
			line.querySelector('.timeline-lt').style.height =
				cardObj.querySelector('.timeline-card-title').offsetHeight +
				'px'
		},
	},
	components: {},
	computed: {},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.Milestone-warp {
	.milestone {
		width: 100%;
		height: 11rem;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		// 三栏都需要浮动
		&-center,
		&-left,
		&-right {
			float: left;
		}
		// 左右两栏固定宽度
		&-left,
		&-right {
			width: 3.15rem;
			background-color: #fff;
		}
		// 中间栏  因为需要自适应所以设置宽度100%
		&-center {
			width: 100%;
			height: 100%;
		}
		&-left {
			margin-left: -100%;
		}
		&-right {
			margin-left: -3.15rem;
		}
		&-inner {
			margin: 0 3.15rem;
		}
		.milestone-inner {
			height: calc(100% - 1.6rem);
			position: relative;
			padding: 0.95rem 0 0.65rem;
			&-title {
				font-size: 0.52rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 700;
				color: #333333;
				line-height: 0.6rem;
				margin-bottom: 0.6rem;
			}
			.milestone-border {
				width: 0.05rem;
				height: 4rem;
				border-left: 0.03rem #127bf2 solid;
				position: absolute;
				left: 1.625rem;
				top: 0.6rem;
			}
			.milestone-border-d {
				width: 0.05rem;
				height: 1.5rem;
				border-left: 0.02rem #127bf2 dashed;
				position: absolute;
				left: 1.625rem;
				bottom: 0.58rem;
			}
			.timeline {
				width: 100%;
				min-height: 0.6rem;
				color: #5e6172;
				padding-left: 0.2rem;
				margin-left: 0.5rem;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				.timeline-time {
					width: 0.8rem;
					min-width: 50px;
					font-size: 0.16rem;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 400;
					color: #127bf2;
					line-height: 0.24rem;
				}
				.timeline-lt {
					min-height: 0.6rem;
					border-left: #127bf2 0.02rem solid;
					position: relative;
					top: 0.28rem;
					left: 0.07rem;
				}
				.timeline-node {
					z-index: 5;
					position: relative;
					bottom: 1px;
					left: 0;
					width: 0.12rem;
					height: 0.12rem;
					background-color: #127bf2;
					border-radius: 50%;
					margin-right: 0.2rem;
				}
				.timeline-triangle {
					z-index: 5;
					position: relative;
					display: none;
					&::after {
						border: 0.09rem solid transparent;
						border-right: 0.1rem solid #ffffff;
						border-right-color: #127bf2;
						width: 0;
						height: 0;
						position: absolute;
						top: -0.11rem;
						right: -0.01rem;
						content: ' ';
					}
					&::before {
						border: 0.11rem solid transparent;
						border-right: 0.11rem solid transparent;
						border-right-color: white;
						width: 0;
						height: 0;
						position: absolute;
						top: -0.13rem;
						right: -0.01rem;
						content: ' ';
					}
				}

				.timeline-card {
					width: 90%;
					cursor: pointer;
					position: relative;

					.timeline-card-title {
						display: block;
						font-size: 0.16rem;
					}
					.timeline-card-content {
						width: 90%;
						font-size: 0.16rem;
						background-color: #127bf2;
						border: 0.01rem solid #127bf2;
						border-radius: 0.05rem;
						padding: 0.1rem;
						box-sizing: border-box;
						display: none;
						.pActive {
							font-size: 0.18rem;
							font-weight: bold;
							line-height: 130%;
							color: #fff;
							opacity: 1;
							margin: 0.1rem 0;
						}
						p {
							font-size: 0.16rem;
							line-height: 0.22rem;
							color: #fff;
							opacity: 0.8;
						}
					}
				}
			}
			.timeline:last-child {
				max-height: 0.5rem;
				position: absolute;
				bottom: 0.6rem;
				.timeline-lt {
					height: 3.6rem;
					border-left: #127bf2 0.02rem dashed !important;
					position: relative;
					top: -1.88rem;
				}
				.timeline-node {
					border: 0.03rem solid #127bf2;
					box-sizing: border-box;
					background-color: #127bf2;
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.Milestone-warp {
		margin: 20px 0;
		.milestone {
			min-height: 600px;
			background-color: #ebf3ff;
			background-size: contain;
			// 左右两栏固定宽度
			&-left,
			&-right {
				width: 16px;
				height: 100%;
			}
			&-right {
				margin-left: -16px;
			}
			&-inner {
				margin: 0 16px;
			}
			.milestone-inner {
				height: calc(100% - 32px);
				padding: 16px;
				&-title {
					font-size: 18px;
					color: #485163;
					line-height: 26px;
				}
				.milestone-border {
					height: 176rem;
				}
				.milestone-border-d {
				}
				.timeline {
					padding-left: 0;
					min-height: 1.2rem;
					margin-left: 0;
					color: #127bf2;
					.timeline-time {
						font-size: 14px;
						line-height: 18px;
					}
					.timeline-lt {
						min-height: 1.8rem;
						border-left: #127bf2 1px solid !important;
						top: 0.68rem;
						left: 6px;
					}
					.timeline-node {
						width: 10px;
						height: 10px;
					}
					.timeline-triangle {
						right: -8px;
						&::after {
							border: 12px solid transparent;
							border-right: 6px solid #ffffff;
							border-right-color: #127bf2;
							width: 0;
							height: 0;
							position: absolute;
							top: -14px;
							right: -2px;
							content: ' ';
						}
						// &::before {
						// 	border: 22px solid transparent;
						// 	border-right: 22px solid transparent;
						// 	border-right-color: white;
						// 	width: 0;
						// 	height: 0;
						// 	position: absolute;
						// 	top: -26px;
						// 	right: -2px;
						// 	content: ' ';
						// }
					}
					.timeline-card {
						width: 230px;
						padding-left: 8px;
						.timeline-card-title {
							width: calc(100% - 20px);
							max-height: 36px;
							font-size: 14px;
							font-family: Source Han Sans, Source Han Sans CN;
							font-weight: 400;
							color: #127bf2;
							line-height: 18px;
							overflow: hidden;
							text-overflow: ellipsis; //超出部分显示省略号
							-webkit-line-clamp: 2;
							display: -webkit-box;
							-webkit-box-orient: vertical;
						}
						.timeline-card-content {
							width: 230px;
							padding: 5px 10px;
							border-radius: 10px 10px 10px 10px;
							p {
								height: 18px;
								line-height: 18px;
								font-size: 12px;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
							p:nth-child(1) {
								font-size: 14px;
								font-weight: 700;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}
					}
				}
				.timeline:last-child {
					width: calc(100% - 32px);
					position: absolute;
					bottom: 21px;
					.timeline-lt {
						border-left: #127bf2 1px dashed !important;
						height: 12.6rem;
						position: relative;
						top: -6.48rem;
					}
					.timeline-node {
						border: 3px solid #127bf2;
					}
				}
			}
		}
	}
}
</style>
