<!-- 关于我们-公司介绍-企业荣誉 -->
<template>
	<div class="Honor-warp">
		<div class="Honor-container" v-lazy:background-image="containerBgUrl">
			<div class="content">
				<div class="inner">
					<div class="title">
						<span class="title-text">企业荣誉</span>
						<span class="title-desc"
							>不断提升自身技术，打造更为出众产品</span
						>
					</div>
					<div class="honor">
						<el-carousel
							arrow="never"
							indicator-position="outside"
							min-height="7.2rem"
							:interval="5000"
						>
							<el-carousel-item
								v-for="(itemImgs, index) in newEnterpriseImg"
								:key="index"
							>
								<div class="enterpriseImg" v-viewer>
									<img
										v-for="(i, index) in itemImgs"
										:key="index"
										v-lazy="i.src"
										alt="证书"
									/>
								</div>
								<div class="enterpriseImg-horizontal" v-viewer>
									<img
										v-for="(i, index) in newHorizontalImg[
											index
										]"
										:key="index"
										v-lazy="i.src"
										alt="证书"
									/>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
			</div>
			<div class="left"></div>
			<div class="right"></div>
		</div>
	</div>
</template>

<script>
import { enterpriseImg, horizontalImg } from './mock'
export default {
	name: 'Honor',
	data() {
		return {
			containerBgUrl: require('@/assets/img/containerBg.png'),
			enterpriseImg: enterpriseImg,
			newEnterpriseImg: [],
			horizontalImg: horizontalImg,
			newHorizontalImg: [],
			totalPages: 1,
		}
	},
	watch: {},
	created() {},
	mounted() {
		const pageSize = this.isMobileEnd ? 6 : 10 // 每页显示的项数
		const pageSizeHorizontal = this.isMobileEnd ? 2 : 4 // 每页横向图片显示的项数

		this.totalPages = Math.ceil(this.enterpriseImg.length / pageSize) // 计算总页数

		for (let i = 1; i <= this.totalPages; i++) {
			const start = (i - 1) * pageSize // 计算起始索引
			let end = i * pageSize // 计算结束索引

			if (end > this.enterpriseImg.length) {
				// 如果结束索引超出数组长度
				const diff = end - this.enterpriseImg.length // 计算需要补充的项数

				for (let j = 0; j < diff; j++) {
					let obj = this.enterpriseImg[j]
					this.enterpriseImg.push(obj) // 从数组开头补充足够的项
				}
			}

			const startH = (i - 1) * pageSizeHorizontal
			let endH = i * pageSizeHorizontal // 计算结束索引

			if (endH > this.horizontalImg.length) {
				// 如果结束索引超出数组长度
				const diffH = end - this.horizontalImg.length
				for (let j = 0; j < diffH; j++) {
					let objH = this.horizontalImg[j]
					this.horizontalImg.push(objH)
				}
			}

			let enterpriseItems = this.enterpriseImg.slice(start, end) // 获取当前页的项
			let horizontalItems = horizontalImg.slice(startH, endH) // 获取当前页的项
			this.newEnterpriseImg.push(enterpriseItems)
			this.newHorizontalImg.push(horizontalItems)
		}
	},
	methods: {},
	components: {},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.Honor-warp {
	.Honor-container {
		width: 100%;
		height: 13.8rem;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}
	// 三栏都需要浮动
	.center,
	.left,
	.right {
		float: left;
	}
	// 左右两栏固定宽度
	.left,
	.right {
		width: 3.15rem;
		background-color: #fff;
	}
	// 中间栏  因为需要自适应所以设置宽度100%
	.center {
		width: 100%;
	}
	.left {
		margin-left: -100%;
	}
	.right {
		margin-left: -3.15rem;
	}
	.inner {
		margin: 0 3.15rem;
	}
	.content {
		width: 100%;
		height: 12.4rem;
		padding-top: 0.86rem;
		.honor {
			min-height: 8rem;
			::v-deep .el-carousel__container {
				height: 10.2rem;
			}
			.enterpriseImg {
				width: 100%;
				display: flex;
				justify-content: space-around;
				align-items: center;
				flex-wrap: wrap;
				img {
					display: block;
					width: 2.2rem;
					height: 3.19rem;
					margin: 0.2rem 0;
				}
				p {
					width: 2.2rem;
				}
			}
			.enterpriseImg-horizontal {
				width: 100%;
				display: flex;
				justify-content: space-around;
				align-items: center;
				flex-wrap: wrap;
				margin-top: 0.4rem;
				img {
					display: block;
					width: 2.9rem;
					height: 2rem;
					// margin: 0.2rem 0;
				}
				p {
					width: 2.9rem;
				}
			}
		}
	}
}
::v-deep .el-carousel__button {
	width: 0.18rem;
	height: 0.04rem;
}
.title {
	width: 100%;
	margin-bottom: 0.6rem;
	text-align: center;
	.title-text {
		height: 0.6rem;
		font-size: 0.52rem;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 500;
		color: #333333;
		line-height: 0.6rem;
		display: block;
	}
	.title-desc {
		display: inline-block;
		font-size: 0.24rem;
		font-family: Source Han Sans, Source Han Sans CN;
		font-weight: 400;
		color: #666666;
		line-height: 0.32rem;
		margin-top: 0.12rem;
	}
}
@media screen and (max-width: 768px) {
	.Honor-warp {
		.Honor-container {
			height: 29rem;
		}
		.left,
		.right {
			width: 16px;
			background-color: #fff;
		}
		.right {
			margin-left: -16px;
		}
		.inner {
			margin: 0 16px;
		}
		.content {
			.honor {
				::v-deep .el-carousel__container {
					height: 23.5rem;
				}
				.enterpriseImg {
					img {
						width: 30%;
						height: 8rem;
					}
				}
				.enterpriseImg-horizontal {
					img {
						width: 50%;
						height: 5.6rem;
					}
				}
			}
		}
	}
	::v-deep .el-carousel__button {
		width: 16px;
		height: 2px;
	}
	.title {
		.title-text {
			height: 24px;
			font-size: 16px;
			line-height: 24px;
		}
		.title-desc {
			font-size: 10px;
			line-height: 18px;
			margin-top: 4px;
		}
	}
}
</style>
